import React from 'react';
import bgImage1 from '../images/pexels-pixabay-207756.jpg';
import bgImage2 from '../images/pexels-pixabay-289737.jpg';
import bgImage3 from '../images/pexels-kaboompics-com-5775.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faCoffe } from '@fortawesome/free-solid-svg-icons'
import { faGooglePlay, faAppStore} from '@fortawesome/free-brands-svg-icons'
import {
  useLoaderData,
  useOutletContext
} from "react-router-dom";

export function Hero(){
  const { website } = useOutletContext();
  const hero_slide = website.hero_slide || []
    
    return <>
  <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-indicators">
        <button data-testid="btn-next" type="button" data-bs-target="#myCarousel" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          {
            hero_slide.length === 0 ? (
                <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
            ) : (
            hero_slide.map((slide, index) => {
                return <button type="button" data-bs-target="#myCarousel" data-bs-slide-to={index+1} className='' aria-current="true" aria-label={`Slide ${index + 1+1}`}></button>
            }))
          }   
        </div>
        <div className="carousel-inner">
        <div data-testid="start-slide" className="carousel-item active"> {/* default first slide*/}
                  <img src={bgImage1} className="d-block w-100" alt="..." />
            <div className="container">
              <div className="carousel-caption text-start">
              <img src={website.badge_url || "https://esomelo.com/static/logo%20william.3f035876.png"} alt="School badge" width="200" height="200" />
                      <h1>{website ? website.school_name: 'Esomelo School Website'}</h1>
                      <p data-testid="motto">{website ? website.school_motto : 'Get a free school website when you starting using Esomelo'}</p>
                      <p>
                        <a href='https://play.google.com/store/apps/details?id=com.esomeleapp&hl=en_IE&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' height="70" /></a>
                        <a href='https://apps.apple.com/ug/app/esomelo/id1624159178'><img alt='Get it on App store' src='https://esomelo.com/static/app-store-badge.2928664f.svg' height="50" /></a>
                      </p>
                    </div>
                  </div>
                </div>
          {
            hero_slide.length === 0 ? (
                <div className="carousel-item">
                  <img src={bgImage2} className="d-block w-100" alt="..." data-testid="image-default"/>
                    <div className="container">
                      <div className="carousel-caption">
                        <h1>Providing quality education for the next generation</h1>
                        <p>We have a qualifed staff team that ensures your children receive the best education.</p>
                        <p>
                          <a 
                            className="btn btn-lg btn-primary" 
                            href="/#about" 
                            style={{backgroundColor: website.primary_color, borderWidth: 0}}>Learn more</a>
                        </p>
                      </div>
                    </div>
                </div>
            ) : (
              hero_slide.map((slide, index) => {
                return <div className='carousel-item'>
                  <img src={slide.image} className="d-block w-100" alt="..." />
                  <div className="container">
                    <div className={`carousel-caption`}>
                      <h1>{slide.title}</h1>
                      <p>{slide.subtitle}</p>
                      {
                        slide.action_link && <p>
                          <a
                            className="btn btn-lg btn-primary"
                            href={slide.action_link}
                            style={{ backgroundColor: website.primary_color, borderWidth: 0 }}>Learn more</a>
                        </p>
                      }
                    </div>
                  </div>
                </div>
            }))         
      }
      {
      //<div className="carousel-item">
      //  <img src={bgImage3} className="d-block w-100" alt="..." />
      //  <div className="container">
      //    <div className="carousel-caption text-end">
      //      <h1>One more for good measure.</h1>
      //      <p>Some representative placeholder content for the third slide of this carousel.</p>
      //      <p><a className="btn btn-lg btn-primary" href="#">Browse gallery</a></p>
      //    </div>
      //  </div>
      //</div>
          }

    </div>
    <button className="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
      <span className="visually-hidden">Previous</span>
    </button>
    <button className="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true"></span>
      <span className="visually-hidden">Next</span>
    </button>
  </div>

    </>;
}