import { Container, Row, Card, Tab, Tabs, Form } from "react-bootstrap";
import { useState } from "react";
import { Calendar as Cal } from "react-date-range";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarXmark } from "@fortawesome/free-solid-svg-icons";
import { isSameDay, isSameMonth, format, parseISO } from "date-fns";
import { useOutletContext } from "react-router-dom";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

const NoEvents = ({ date = null }) => {
  return (
    <div className="mt-4 text-center">
      <FontAwesomeIcon icon={faCalendarXmark} className="me-2" size="lg" />
      <span>No events {date ? ` on ${date}` : ""}</span>
    </div>
  );
};

export function Events() {
  const { website } = useOutletContext();
  const _events = website.upcoming_events;


  const eventDates = _events.map((event) => parseISO(event.start_date || event.dates));
  const [events, setEvents] = useState(_events);
  const [noEventsOnDate, setNoEventsOnDate] = useState(null);
  const filters = ["All events", "Today", "This month"];

  const onDateChange = (date) => {
    setEvents(() => {
      const eventsOnDate = _events.filter((event) => {
        return isSameDay(new Date(event.start_date), date);
      });

      if (!eventsOnDate.length)
        setNoEventsOnDate(format(date, "do MMMM, yyyy"));

      return eventsOnDate;
    });
  };

  const onFilterChange = (event) => {
    switch (event.target.id) {
      case "All events":
        setEvents(_events);
        break;
      case "Today":
        setNoEventsOnDate(format(new Date(), "do MMMM, yyyy"));
        setEvents(
          _events.filter((event) =>
            isSameDay(new Date(event.start_date), new Date())
          )
        );
        break;
      case "This month":
        setNoEventsOnDate(null);
        setEvents(
          _events.filter((event) =>
            isSameMonth(new Date(event.start_date), new Date())
          )
        );
        break;
    }
  };

  function isEvent(day) {
    return eventDates.find((date) => isSameDay(date, day));
  }

  function highlightEvent(day) {
    let highlight = null;
    if (isEvent(day)) {
      highlight = (
        <div
          style={{
            height: "10%",
            width: "100%",
            borderRadius: "",
            background: "rgb(61, 145, 255)",
            position: "absolute",
            bottom: 0,
            right: 0,
          }}
        />
      );
    }
    return (
      <div>
        {highlight}
        <span>{format(day, "d")}</span>
      </div>
    );
  }

  return (
    <Container style={{marginTop: 50}}>
      <Row>
        <div className="col-md-7">
          <Tabs defaultActiveKey="upcoming" id="id-upcoming-events">
            <Tab eventKey="upcoming" title="Upcoming Events">
              {events.length ? (
                events.map((event, index) => {
                  return (
                    (Object.keys(event).includes("start_date") || Object.keys(event).includes("dates")) && <Card className="mb-2 card-list-item">
                      <Card.Body key={index}>
                        <Card.Title className="">{event.title}</Card.Title>
                        <Card.Subtitle>
                          {format(parseISO(event.start_date || event.dates), "do MMMM, yyyy")}
                        </Card.Subtitle>
                        <Card.Text className="mt-3">{event.details}</Card.Text>
                      </Card.Body>
                    </Card>
                  );
                })
              ) : (
                <NoEvents date={noEventsOnDate} />
              )}
            </Tab>
            <Tab eventKey="past" title="Past Events">
              <NoEvents />
            </Tab>
          </Tabs>
        </div>
        <div className="col-md-5">
          <Cal
            style={{ width: "100%" }}
            onChange={onDateChange}
            dayContentRenderer={highlightEvent}
          />
          <div style={{ width: "100%" }}>
            <Card onClick={() => {}} className="">
              <Card.Body>
                <Card.Title className="">Filter</Card.Title>
                <Form>
                  {filters.map((filter, index) => (
                    <div key={index} className="">
                      <Form.Check
                        type="radio"
                        id={filter}
                        label={filter}
                        name="filters"
                        onChange={onFilterChange}
                      />
                    </div>
                  ))}
                </Form>
              </Card.Body>
            </Card>
          </div>
        </div>
      </Row>
      
    </Container>
  );
}
