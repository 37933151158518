import React, { useState } from 'react';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';


export function MapContainer(props) {
    const [showingInfoWindow, setShowingInfoWindow] = useState(false);
    const [activeMarker, setActiveMarker] = useState({});
    const [selectedPlace, setSelectedPlace] = useState({});

    const { gps } = props;
    const DEFAULT_GPS = { lat: 0.322681, lng: 32.575236 };
    const coords = {
      lat: gps ? gps[0]: DEFAULT_GPS.lat,
      lng: gps ? gps[1]: DEFAULT_GPS.lng,
    };

    const containerStyle = {
        // position: 'relative',  
        width: props.width,
        height: 500
      }


    const onMarkerClick = (prps, marker, e) => {
      setShowingInfoWindow(true);
      setActiveMarker(marker);
      setSelectedPlace(prps);
    }

    const onMapClicked = () => {
      if (showingInfoWindow) {
        setShowingInfoWindow(false);
        setActiveMarker(null);
      }
    };
      
    return (
      <Map 
        containerStyle={containerStyle}
        google={props.google}
        zoom={14}
        initialCenter={coords}
        onClick={onMapClicked}
        data-testid={"map-container"}
        >
        <Marker onClick={onMarkerClick}
                name={'School location'} />

      <InfoWindow
          marker={activeMarker}
          visible={showingInfoWindow}>
            <div>
              <h3>School location</h3>
            </div>
        </InfoWindow>

      </Map>
    );
}
 
export default GoogleApiWrapper({
  apiKey: "AIzaSyDDHRV9X_pGjMOFqUFVYoHuG5Gpjs2Fy4E"
})(MapContainer);