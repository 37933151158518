import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import "./css/gallery.css"; // import the CSS file
import { PhotoGallery } from '../components/PhotoGallery';

// The PhotoGallery component is making components very hard to test. For some reason, Jest
// keeps on asking for it even when we do not ask for it explicitly in some files.
// The intitial approach was to mock it out in some of those test files
// and where mocking alone is not enough, then we shall pass a flag during testing
// to stop rendering the PhotoGallery component and that makes Jest happy.
// hence the introduction of the showPhotoGallery prop
export const Gallery = ({showPhotoGallery=true}) => {
  const { website } = useOutletContext();
  return (
    <>
        <div className="image-gallery" data-testid="gallery">
          <div className="row featurette" id="gallery">
          <h2 className="featurette-heading fw-normal lh-1" style={{marginTop: '0rem'}}>Recent <span style={{color: website.primary_color}}>Photos</span></h2>
            {showPhotoGallery && <PhotoGallery photos={website.photos.data || []}/>} 
            </div>
      
      <a href="/gallery" className="link-primary" style={{color: website.primary_color}}>View more photos</a>
      

      </div>
    </>
  );
};


