import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { useOutletContext } from "react-router-dom";

export function Aboutus(){
    const { website } = useOutletContext();

    return <Container className="pt-5">
        <h2 className="featurette-heading fw-normal lh-1" style={{marginTop: '0rem'}}>About <span style={{color: website.primary_color}}>{website.school_name}</span></h2>

        <div dangerouslySetInnerHTML={{__html: website?.detailed_about_school ? website?.detailed_about_school : website?.about_school}}></div>
  </Container>
}