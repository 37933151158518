import React from 'react';
import { Container } from 'react-bootstrap';
import image1 from '../images/pexels-pixabay-159751.jpg';
import {
  useOutletContext
} from "react-router-dom";
import { Admissions } from './Admissions';

export function About () {
  const { website } = useOutletContext();
  const defaultAbout = 'Esomelo is a one stop mobile first school management solution for all your school administration needs.';

  return (<Container>

<div className="row featurette" id="about">
  <div className="col-md-7 align-top" data-testid="school-about">
    <h2 className="featurette-heading fw-normal lh-1" style={{marginTop: '0rem'}}>
      About <span  style={{color: website.primary_color}}>{website? website.school_name : 'School name'}</span>
    </h2>
    <p className="lead" dangerouslySetInnerHTML={{__html: website ? website.about_school : defaultAbout}}></p>
  </div>
  <div className="col-md-5">
    {
      //<img src={image1} width="500" height="350"/>
    }
    <svg width="500px" height="450px" viewBox="0 0 500 450">
  <defs>
    <mask id="my-svg-mask2">
      <circle id="Oval" fill="#FFFFFF" cx="90.4648437" cy="256.464844" r="81.4648437"></circle>
      <circle id="Oval" fill="#FFFFFF" cx="209.587891" cy="300.587891" r="130.5878906"></circle>
    </mask>
  </defs>
  <image mask="url(#my-svg-mask2)" xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={image1} width="400" height="600"></image>
</svg>
  </div>
</div>

{/* {website.admission_requirements && <hr className="featurette-divider-half"/>}

<div className="row featurette" id="admissions">
  <div className='col-md-12'>
    <Admissions />
  </div>

</div> */}

    </Container>);
}