import React from 'react';
import { Container } from 'react-bootstrap';
import image1 from '../images/pexels-karol-d-325154.jpg';
import { useOutletContext } from "react-router-dom";

export function Fees() {
    const { website } = useOutletContext();
    const labels = (website.school_fees && website.school_fees.components) ? website.school_fees.components.map(component => component.label) : []
    const formatter = new Intl.NumberFormat('en-US');

    if(!website?.school_fees) return null;
    if(!website?.school_fees?.components) return null;

    return (<Container>

        <div className="row featurette" id="fees">
        <div className="col-md-12 order-md-2">
            <h2 className="featurette-heading fw-normal lh-1" style={{marginTop: '0rem'}}>School <span style={{color: website.primary_color}}>Fees</span></h2>
            <p className="lead">Contact the school for additional charges.</p>

        <table className="table table-bordered">
        <thead>
            <tr>
            <th scope="col">Class</th>
            {labels.map(label => <th scope="col">{label}</th>)}
            <th scope="col">Total</th>
            </tr>
        </thead>
        <tbody>
            {Object.keys(website.school_fees.fees_structure).map(klass => <><tr>
            <th scope="row">{klass}</th>
            {Object.values(website.school_fees.fees_structure[klass]).map((fee) => <td>{formatter.format(parseInt(fee))}</td>)}
            <td>{formatter.format(Object.values(website.school_fees.fees_structure[klass]).reduce((a, b) => (parseInt(a) + parseInt(b)), 0))}</td>
            </tr>
            </>)}
           
        </tbody>
        </table>

        </div>
        </div>

    </Container>);
}