import ParticlesBg from "particles-bg";
import balloon_red from "../images/balloon-red.png"
import balloon_pink from "../images/balloon-pink.png"
import letterA from "../images/A.png"
import letterB from "../images/B.png"
import letterC from "../images/C.png"
import letterD from "../images/D.png"

export default Animation = ({ children }) => {
  const config = {
    num: [0, 1],
    rps: 0.1,
    radius: [5, 10],
    life: [7, 8],
    v: [0, 0.5],
    tha: [-60, 60],
    body: letterA,
    alpha: [0.6, 0],
    scale: [0.1, 0.4],
    position: "all",
    color: ["#af6000"],
    cross: "dead",
    // emitter: "follow",
    random: 2,
    bg: true,
  };
  
  return (
    <div className="animation">
      <ParticlesBg type="custom" config={config} bg={true} />
      <ParticlesBg type="custom" config={{...config, body: letterB}} bg={true} />
      {/* <ParticlesBg type="custom" config={{...config, body: letterC}} bg={true} /> */}
      {/* <ParticlesBg type="custom" config={{...config, body: letterD}} bg={true} /> */}
      {children}
    </div>
  );
};
