import React from "react";
import Animation from "./Animation";

const layouts = {
  nursery: { showAnimationBg: true },
  primary: { showAnimationBg: false },
  secondary: { showAnimationBg: false },
};

const LayoutContext = React.createContext(layouts);

const importStyles = (school_type) => {
  switch (school_type) {
    case "nursery":
      import("./css/nursery.css");
      break;
    case "primary":
      import("./css/primary.css");
      break;
    case "secondary":
      import("./css/secondary.css");
      break;
  }
};

export const useLayout = () => React.useContext(LayoutContext);

const Style = ({primaryColor}) => {
  // This component does away with the problem of having to access
  // the `primary_color` value for custom styling inside css files.
  // Some styles e.g. box-shadows, default colors etc. need to be overriden in order
  // to promote uniformity and consistency across the site.
  // Another option is to use a library like styled_components
  // but this proved to be a good and simple approach, at least for the
  // moment
  return <style>
    {`
      :root{
        --primary-color: ${primaryColor || "darkred"};
      }
    `}
  </style>
}

export const LayoutProvider = ({ school_type, primary_color, children }) => {
  let showAnimationBg = false;
  if (school_type) {
    importStyles(school_type);
    showAnimationBg = layouts[school_type]["showAnimationBg"];
  }
  return (
    <LayoutContext.Provider value={layouts[school_type]}>
      <Style primaryColor={primary_color}/>
      {showAnimationBg ? (
         <Animation>{children}</Animation>
      ) : (
        children
      )}
    </LayoutContext.Provider>
  );
};
