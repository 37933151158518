import React, { useState, useEffect } from 'react';
import { Jumbotron, Container, Nav } from 'react-bootstrap';
import { Hero, Footer, Hightlights, Features, Header, About } from './components';
    useOutletContext
import { Outlet , useOutletContext} from "react-router-dom";
import {
  useLoaderData
} from "react-router-dom";
import { LayoutProvider } from './components/LayoutProvider';
//import ReactGA from 'react-ga4';

export function App(props) {
    const website = useLoaderData();
    const {school_type, primary_color} = website;
   // ReactGA.initialize('G-PY1ZJ3QBLV');

    //set window title
    window.document.title = website.school_name;

    // useEffect(() => {
    //   ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    // }, []);

    return (<LayoutProvider school_type={school_type} primary_color={primary_color}>
      <Header website={website}/>
      <Outlet context={{ website }}/>
      <Footer website={website}/>
    </LayoutProvider>);
  }
