import React, { useMemo, useState } from 'react';
import PhotoAlbum from "react-photo-album";
import Lightbox, { IMAGE_FIT_COVER } from "yet-another-react-lightbox";
import "yet-another-react-lightbox/dist/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/dist/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/dist/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/dist/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/dist/plugins/zoom";
import Captions from "yet-another-react-lightbox/dist/plugins/captions";
import "yet-another-react-lightbox/dist/plugins/thumbnails/thumbnails.css";
import "yet-another-react-lightbox/dist/plugins/captions/captions.css";

export function PhotoGallery({photos}){

    const breakpoints = [3840, 2400, 1080, 640, 384, 256, 128, 96, 64, 48];
    const [index, setIndex] = useState(-1);
    const [ album, setAlbum] = useState([]);

    const photosToShow = photos.map(({ image, width, height, description }) => ({
        src: image,
        width,
        height,
        title: description,
        description: description,
    }));

    const slides = photos.map(({ image, width, height, description }) => ({
        src: image,
        width,
        height,
        title: description,
        description: description,
        srcSet: breakpoints.map((breakpoint) => ({
            src: image,
            width: breakpoint,
            height: Math.round((width / height) * breakpoint),
        })),
    }));

    return <>
            <PhotoAlbum photos={photosToShow} layout="rows" targetRowHeight={150} onClick={({ index }) => setIndex(index)} />
            <Lightbox
                slides={slides}
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                plugins={[Fullscreen, Slideshow, Thumbnails, Zoom, Captions]}
                thumbnails={{imageFit: IMAGE_FIT_COVER}}
            />

            {photos.length === 0 ? <div className="card">
            <div className="card-body">
                <blockquote className="blockquote mb-0">
                <p>No photos available</p>
                
                </blockquote>
            </div>
            </div>: null}
        </>
    ;
}