import React, { useEffect, useState } from 'react';
import { Jumbotron, Container, Nav } from 'react-bootstrap';
import { Hero, Footer, Hightlights, Features, Header, About, Fees, Contact, Events } from '../components';
import { Outlet } from "react-router-dom";
import ImageGallery from 'react-image-gallery';
import { PhotoGallery } from '../components/PhotoGallery';
import { useOutletContext } from "react-router-dom";

export function Elections() {
    const { website } = useOutletContext();
    const [candidates, setCandidates] = useState({});
    const year = new Date().getFullYear();
   
    const fetchCandidates = async (p) => {
        try{
            const res = await fetch(`${website.API_URL}/api/website/${website.school_key}/elections`);
            const resp = await res.json();
        
            setCandidates(resp.data || []); 

            console.log(resp.data)

         }catch(e){
            console.log(e);
        }
    }

    useEffect(() => {
        document.title = "Elections - " + year + " - " + website.school_name;
        fetchCandidates();

    }, [1]);

    useEffect(() => {
        setTimeout(() => {
            fetchCandidates();
        }, 60*1000);
    }, [candidates]);


    return (<>
        <Container style={{marginTop: 50}} data-testid="elections">
             <h2 className="featurette-heading fw-normal lh-1" style={{marginTop: '0rem'}}>School <span style={{color: website.primary_color}}>Elections - {year}</span></h2>
             {Object.keys(candidates).length > 0 ? 
                <div className="row">
                {Object.keys(candidates).map((candidate, index) => {
                    return (
                        <div className="col-md-12" key={index}>
                            <div className="card" style={{marginBottom: 20}}>
                                {/* <img src={candidate.image} className="card-img-top" alt={candidate.name} /> */}
                                <div className="card-body">
                                    <h5 className="card-title">{candidate}</h5>

                                    {candidates[candidate].filter(v => v.last_name !== null).map((c, i) => {
                                        return (<>
                                            <div key={i} style={{display: 'flex'}}>
                                                <p className="card-text text-uppercase" style={{flex: 1}}>{c.last_name} {c.first_name}</p>
                                                <p className="card-text text-bold" style={{fontWeight: 'bold', fontSize: 20}}>{c.votes ? c.votes : 0}</p>
                                                
                                            </div>
                                            <div style={{flex: 1, borderBottom: 1,}}></div>
                                            </>
                                    
                                        );
                                    })}
                                    {/* <p className="card-text">{candidate.post_name}</p>
                                    <p className="card-text">{candidate.votes}</p> */}
                                </div>
                            </div>
                        </div>
                    );}
                )}
                </div>
                : <p>Loading...</p>
            }
        </Container>
    </>);
  }