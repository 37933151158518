import React, { useEffect, useState } from "react";
import { Jumbotron, Container, Nav } from "react-bootstrap";
import {
  Hero,
  Footer,
  Hightlights,
  Features,
  Header,
  About,
  Fees,
  Contact,
  Events,
} from "../components";
import { Outlet } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import { PhotoGallery } from "../components/PhotoGallery";
import { useOutletContext } from "react-router-dom";

export function Gallery({ showPhotoGallery = true }) {
  // TODO; add a feature for albums to the gallery
  const { website } = useOutletContext();
  const [photos, setPhotos] = useState(website.photos.data || []);
  const [page, setPage] = useState(1);

  const fetchPhotos = async () => {
    
    try {
      if (page === -1) return;
      const res = await fetch(`${website.API_URL}/api/website/${website.school_key}/gallery?page=${page}`);
      const resp = await res.json();

      if (resp.data.data.length === 0) {
        setPage(-1);
        return;
      } else {
        if (page === 1) {
          setPhotos(resp.data.data);
        } else {
          setPhotos([...photos, ...resp.data.data]);
        }
      }
      
      setTimeout(() => {
        setPage(page + 1);
      }, 5000);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    document.title = "Gallery | " + website.school_name;
    fetchPhotos();
  }, [page])

  return (
    <>
      <Container style={{ marginTop: 50 }}>
        <h2
          className="featurette-heading fw-normal lh-1"
          style={{ marginTop: "0rem", marginBottom: "2rem" }}
        >
          School <span style={{ color: website.primary_color }}>Gallery</span>
        </h2>
        {showPhotoGallery && <PhotoGallery photos={photos} key={page} />}
      </Container>
    </>
  );
}
