import React from 'react';
import { Container, ToggleButton, ToggleButtonGroup, Button } from "react-bootstrap";
import { useOutletContext } from "react-router-dom";
import { useState } from "react";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function Admissions(){
    const { website } = useOutletContext();
    const [value, setValue] = useState(0)
    
    // const showRequirementsForClass = (val) => {
    //     setValue(val)
    // }

    if (!website.admission_requirements) return null

    return <Container>
        <h2 className="fw-normal lh-1 mt-5" style={{marginTop: '0rem'}}>Admission <span style={{color: website.primary_color}}>requirements</span></h2>
        <i><h5 className=" featurette-headingfw-normal lh-1 font-italic" style={{marginBottom: '1rem', color: "#747474"}}>We are thrilled to learn that you are interested in joining our school.</h5></i>
        {/* <ToggleButtonGroup type="radio" name="radio-btns" value={value} onChange={showRequirementsForClass}>
            {website.school_classes.map((klass, idx) => {
                return <ToggleButton key={idx} id={`radio-btn-${idx}`} className="ctb" value={idx} variant="outline-primary">
                    {klass}
                </ToggleButton>
            })}
        </ToggleButtonGroup> */}

        <ul className="adm-reqs">
            {website.admission_requirements.requirements.map((req, idx) => {
                return <li key={idx}>{req}</li>
            })}
        </ul>

        {website.admission_requirements.documents.map((doc, idx) => {
            // show any documents if present
            return <Button href={doc.href} key={`doc-${idx}`} variant="primary" className="mt-4" style={{borderRadius: "0px", backgroundColor: website.primary_color, borderColor: website.primary_color, marginRight: "0.5rem"}}>
                <FontAwesomeIcon icon={faFilePdf} /> {`${doc.title} application form`}
            </Button>
        })}

    </Container>
}