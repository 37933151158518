import { createRoot } from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import { App } from "./App";
import './scss/styles.scss';
import * as React from 'react';
import { ErrorPage } from "./pages/ErrorPage";
import { Gallery } from "./pages/Gallery";
import { Elections } from "./pages/Elections";
import { Events } from "./pages/Events";
import { Aboutus } from "./pages/Aboutus";

import {
    createBrowserRouter,
    RouterProvider,
  } from "react-router-dom";
import { Home } from "./pages/Home";
import { Privacy } from "./pages/Privacy";
import { Apply } from "./pages/Apply";

var API_URL = 'https://api.esomelo.com';

async function enableMocking() {
    const { worker } = await import('./mocks/browser')
    return worker.start()
}

const router = createBrowserRouter([
{
    path: "/",
    loader: async ({request}) => {

        let domain = window.location.host
        let subdomain = domain.split('.')[1] ? domain.split('.')[0] : false
        if (domain.includes(".localhost")) await enableMocking()   
        const res = await fetch(`${API_URL}/api/website/${subdomain}`);
        const resp = await res.json();
        return {...resp.data, API_URL};
    },
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
        {
            path: "/",
            element: <Home />
        },
        {
            path: "/gallery",
            element: <Gallery />
        },
        {
            path: "/elections",
            element: <Elections />
        },
        {
            path: "/privacy",
            element: <Privacy />
        },
        {
            path: "/events",
            element: <Events />
        },
        {
            path: "/apply",
            element: <Apply />
        },
        {
            path: "/aboutus",
            element: <Aboutus />
        }
    ],



},
]);

const container = document.getElementById("app");
const root = createRoot(container)
root.render(<React.StrictMode>
        <RouterProvider router={router} />
</React.StrictMode>);
