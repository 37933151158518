import React from "react";
import { Container, Card } from "react-bootstrap";
import image1 from "../images/pexels-pixabay-159751.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { useOutletContext } from "react-router-dom";
import { useEffect } from "react";
import { format, parseISO } from "date-fns";

export function Events() {
  const { website } = useOutletContext();

  const [currentEvent, setCurrentEvent] = React.useState({});

  useEffect(() => {
    setCurrentEvent(website.upcoming_events[0]);
  }, [website.upcoming_events]);

  return (
    <Container>
      <div className="row featurette" id="events">
        <h2
          className="featurette-heading fw-normal lh-1"
          style={{ marginTop: "0rem" }}
        >
          Upcoming <span style={{ color: website.primary_color }}>Events</span>
        </h2>
        <div className="col-md-7 align-top">
          <Card data-testid="upcoming-events">
            {website.upcoming_events.map((v, i) => (
              Object.keys(v).includes("start_date") && <Card.Body
                onClick={() => setCurrentEvent(v)}
                key={i}
                className="upcoming-event"
                style={{
                  backgroundColor:
                    currentEvent.id === v.id ? "rgb(223, 226, 228)" : "",
                }}
              >
                <Card.Title
                  className={`${
                    currentEvent.id === v.id ? "text-bg-secondry" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faCalendar} /> {v.title}
                </Card.Title>
                <Card.Text>{format(parseISO(v.start_date), "do MMMM, yyyy")}</Card.Text>
                <Card.Text>
                  <FontAwesomeIcon
                    icon={faArrowCircleRight}
                    className="float-end"
                    size="lg"
                    onClick={() => setCurrentEvent(v)}
                  />
                </Card.Text>
              </Card.Body>
            ))}
          </Card>

          <div className="mt-3">
            <a
              href="/events"
              className="link-primary"
              style={{ color: website.primary_color }}
            >
              View all events
            </a>
          </div>
        </div>
        <div className="col-md-5 align-top">
          <Card className="mt-0">
            <Card.Body>
              {currentEvent ? (
                <>
                  <Card.Text
                    className="h2"
                    style={{ color: website.primary_color }}
                  >
                    <FontAwesomeIcon icon={faCalendar} /> {currentEvent.title}
                  </Card.Text>
                  {/* For some reason, the line below will not work. Also if your tried calling
                    any method on currentEvent.start_date, say `toString() it will say you can't call a method on
                    "undefined" yet it is very clear that the "start_date" is not undefined!
                    I have chosen to leave this out for now, since it is taking a lot of time to resolve
                 */}
                  {/* <Card.Text className="just">{format(parseISO(currentEvent.start_date), "do MMMM, yyyy")}</Card.Text> */}
                  <Card.Text className="just">{currentEvent.details}</Card.Text>
                </>
              ) : (
                <Card.Text className="just"> ... </Card.Text>
              )}
            </Card.Body>
          </Card>
        </div>
      </div>
    </Container>
  );
}
