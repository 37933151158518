import { Container, Spinner, Modal } from "react-bootstrap";
import { Admissions } from "../components/Admissions";
import { useOutletContext } from "react-router-dom";
import { useState, useRef } from "react";
import ReCAPTCHA from 'react-google-recaptcha'


const range = (end, start = 0, step = 1) =>
    Array.from(
      { length: Math.ceil((end - start) / step) },
      (_, i) => i * step + start
    );

export function Apply() {
    const { website } = useOutletContext();
    // const countries = [
    //     "Afghanistan","Albania","Algeria","American Samoa","Andorra","Angola","Anguilla",
    //     "Antarctica","Antigua and Barbuda","Argentina","Armenia","Aruba","Australia","Austria","Azerbaijan","Bahamas (the)","Bahrain","Bangladesh","Barbados","Belarus","Belgium","Belize","Benin",
    //     "Bermuda","Bolivia (Plurinational State of)","Bhutan","Bonaire, Sint Eustatius and Saba","Bosnia and Herzegovina","Botswana","Bouvet Island","Brazil",
    //     "British Indian Ocean Territory (the)","Brunei Darussalam","Bulgaria","Burkina Faso","Burundi","Cabo Verde","Cambodia","Cameroon","Canada","Cayman Islands (the)",
    //     "Central African Republic (the)","Chad","Chile","China","Christmas Island","Cocos (Keeling) Islands (the)","Colombia","Comoros (the)","Congo (the Democratic Republic of the)","Congo (the)","Cook Islands (the)",
    //     "Costa Rica","Croatia","Cuba","Curaçao","Cyprus","Czechia","Côte d'Ivoire","Denmark","Djibouti","Dominica","Dominican Republic (the)","Ecuador","Egypt",
    //     "El Salvador","Equatorial Guinea","Eritrea","Estonia","Eswatini","Ethiopia","Falkland Islands (the) [Malvinas]","Faroe Islands (the)","Fiji","Finland","France","French Guiana",
    //     "French Polynesia","French Southern Territories (the)","Gabon","Gambia (the)","Georgia","Germany","Ghana","Gibraltar","Greece","Greenland","Grenada","Guadeloupe",
    //     "Guam","Guatemala","Guernsey","Guinea","Guinea-Bissau","Guyana","Haiti","Heard Island and McDonald Islands","Holy See (the)","Honduras","Hong Kong",
    //     "Hungary","Iceland","India","Indonesia","Iran (Islamic Republic of)","Iraq","Ireland","Isle of Man","Israel","Italy","Jamaica",
    //     "Japan","Jersey","Jordan","Kazakhstan","Kenya","Kiribati","Korea (the Democratic People's Republic of)","Korea (the Republic of)","Kuwait","Kyrgyzstan","Lao People's Democratic Republic (the)","Latvia","Lebanon",
    //     "Lesotho","Liberia","Libya","Liechtenstein","Lithuania","Luxembourg","Macao","Madagascar","Malawi","Malaysia","Maldives","Mali","Malta",
    //     "Marshall Islands (the)","Martinique","Mauritania","Mauritius","Mayotte","Mexico","Micronesia (Federated States of)","Moldova (the Republic of)","Monaco","Mongolia","Montenegro","Montserrat","Morocco","Mozambique",
    //     "Myanmar","Namibia","Nauru","Nepal","Netherlands (the)","New Caledonia","New Zealand","Nicaragua","Niger (the)","Nigeria","Niue","Norfolk Island","Northern Mariana Islands (the)","Norway",
    //     "Oman","Pakistan","Palau","Palestine, State of","Panama","Papua New Guinea","Paraguay","Peru","Philippines (the)","Pitcairn","Poland","Portugal","Puerto Rico","Qatar",
    //     "Republic of North Macedonia","Romania","Russian Federation (the)","Rwanda","Réunion","Saint Barthélemy","Saint Helena, Ascension and Tristan da Cunha","Saint Kitts and Nevis","Saint Lucia","Saint Martin (French part)","Saint Pierre and Miquelon","Saint Vincent and the Grenadines","Samoa","San Marino",
    //     "Sao Tome and Principe","Saudi Arabia","Senegal","Serbia","Seychelles","Sierra Leone","Singapore","Sint Maarten (Dutch part)","Slovakia","Slovenia","Solomon Islands","Somalia","South Africa","South Georgia and the South Sandwich Islands",
    //     "South Sudan","Spain","Sri Lanka","Sudan (the)","Suriname","Svalbard and Jan Mayen","Sweden","Switzerland","Syrian Arab Republic","Taiwan","Tajikistan","Tanzania, United Republic of","Thailand","Timor-Leste",
    //     "Togo","Tokelau","Tonga","Trinidad and Tobago","Tunisia","Turkey","Turkmenistan","Turks and Caicos Islands (the)","Tuvalu","Uganda","Ukraine","United Arab Emirates (the)","United Kingdom of Great Britain and Northern Ireland (the)","United States Minor Outlying Islands (the)",
    //     "United States of America (the)","Uruguay","Uzbekistan","Vanuatu","Venezuela (Bolivarian Republic of)","Viet Nam","Virgin Islands (British)","Virgin Islands (U.S.)","Wallis and Futuna","Western Sahara","Yemen","Zambia","Zimbabwe","Åland Islands"
    // ];

    let studentFieldNumRows = null
    let parentFieldNumRows = null
    const [documents, setDocuments] = useState([])
    const [klass, setKlass] = useState(website.school_classes[0])
    const [submitted, setSubmitted] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const recaptcha = useRef()

    const studentFields = website.application?.fields?.student
    const parentFields = website.application?.fields?.parent

    if(studentFields?.length && parentFields?.length){
        studentFieldNumRows = range(studentFields.length, step=2)
        parentFieldNumRows = range(parentFields.length, step=2) // TODO: Fix this bug - not all parent fields are showing.
    }

    const columnsPerRow = 2
    let currentFieldStudents = 0
    let currentFieldParents = 0

    const filterDocumentsByClass = (event) => {
        setKlass(event.target.value)
        setDocuments(website.application.documents.filter(doc => doc.targets.includes(event.target.value)))
    }

    const handleSubmit = async (event) => {
        event.preventDefault()

        // do some sanitising of the different fields here

        setIsSubmitting(true)
        
        // TODO; once the captcha is setup, switch to this
        // const captchaValue = await recaptcha.current.executeAsync()
        const captchaValue = ""
        const resp = await fetch(`${website.API_URL}/api/website/${website.school_key}/verify-captcha?captchaValue=${captchaValue}`)

        let res = await resp.json()
        if(!res.success) throw new Error("Could not verify CAPTCHA value")

        setIsSubmitting(false)
        setSubmitted(true)
    }

    return <Container style={{marginTop: 50}}>

    <Admissions />

    {website.apply_online &&
    <Container className="container-form">
      {!submitted ? <>
        <h2 className="fw-bold lh-1 mt-2 mb-2" style={{marginTop: '0rem', width: "100%", margin: "auto"}}>Online application</h2>
        <h4 className="fw-normal">Welcome to the online admission form! This form is to be filled in by all applicants wishing to join {website.school_name}</h4>

        <form className="mt-4" target="/" method="POST" onSubmit={handleSubmit}>
            <h2 className="fw-normal lh-1 mt-5">Student's <span style={{color: website.primary_color}}>details</span></h2>
            <span style={{color: "red"}}>Fields marked with * are required</span>

            {studentFieldNumRows.map((row, idx) => {
                return <div className="row" style={{marginTop: "1rem"}}>
                    {studentFields.slice(currentFieldStudents, currentFieldStudents+=columnsPerRow).map((field, idx) => {
                        if (field.label.toLowerCase() === "class" &&  field.type === "select") {
                            return <div className="col">
                                <label htmlFor="class">{field.label}</label><span style={{color: "red"}}>{field.required && " *"}</span>
                                <select className="form-control" id="class" value={klass} onChange={filterDocumentsByClass} name={field.name} required="true">
                                    {website.school_classes.map((klass, idx) => {
                                        return <option key={idx} value={klass}>{klass}</option>
                                    })}
                                </select>
                            </div>
                        }

                        return <div className="col">
                            <label htmlFor={`student-${field.label}`}>{field.label}</label><span style={{color: "red"}}>{field.required && " *"}</span>
                            <input className="form-control" type={field.type} name={field.name} id={`student-${field.label}`} key={idx} required={field.required} placeholder={field.label}/>
                        </div>
                    })}
                </div>
            })}

            <hr className="featurette-divider-half"/>

            <h2 className="fw-normal lh-1 mt-5" style={{marginTop: '0rem'}}>Parent's <span style={{color: website.primary_color}}>details</span></h2>
            <span style={{color: "red"}}>Fields marked with * are required</span>

            {parentFieldNumRows.map((row, idx) => {
                return <div className="row" style={{marginTop: "1rem"}}>
                    {parentFields.slice(currentFieldParents, currentFieldParents+=columnsPerRow).map((field, idx) => {
                        return <div className="col">
                            <label for={`parent-${field.label}`}>{field.label}</label><span style={{color: "red"}}>{field.required && " *"}</span>
                            <input className="form-control" type={field.type} name={field.name} id={`parent-${field.label}`} key={idx} required={field.required} placeholder={field.label}/>
                        </div>
                    })}
                </div>
            })}

            <hr className="featurette-divider-half"/>

            {documents.length > 0 && <>
                <h2 className="fw-normal lh-1 mt-5" style={{marginTop: '0rem'}}>Please attach the following documents</h2>
                    <div className="row">
                        {documents?.map((doc, idx) => {
                            return <div className="col" key={idx}>
                            <label for="file-upload">{doc.title}</label><br/>
                            <input type="file" className="form-control-file" id="file-upload"/>
                        </div>
                        })}
                    </div>
            </>}

            <div className="row mb-4 mt-4">
            <div className="col">
                <button type="submit" className="btn btn-primary" disabled={isSubmitting} style={{borderRadius: "0px", backgroundColor: website.primary_color, borderColor: website.primary_color, marginRight: "0.5rem"}}>
                    Apply now 
                    {isSubmitting && <Spinner className="ms-3" animation="border" size="sm" />}
                </button>
            </div>
            </div>

        </form>
      </> :<h5 className=" featurette-headingfw-normal lh-1 font-italic" style={{marginBottom: '1rem', color: "#747474"}}>Your application form has been submitted!</h5>
    }

      <ReCAPTCHA size="invisible" sitekey="6LdDrQwqAAAAACrl3j56454ld9YehXlirQASG4zf" ref={recaptcha} />
    </Container>}
    </Container>;
}
